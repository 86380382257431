@import './reset';
@import './_media-queries';
@import './generic-colors';
@import './../../../node_modules/sass-rem/_rem.scss';
@import '../mixins/lists';
@import './vertical/liquor/vars';
@import './graphite/base';

/**
 * Custom colors
 */
$color-brand-apple-blossom-red-lighter: #b35f4d;
$color-brand-black-pearl-darkest: #03131c;
$color-brand-white-smoke: #f4f3f2;
$color-brand-regent-blue: #9ec4d7;
$color-brand-endeavour-blue: #265986;
$color-brand-astral-blue: #3673a8;
$color-brand-cognac: #983c29;
$color-brand-la-croix-peach: #f2decb;

// Gray anomalies
$color-gray-05: #f4f3f3;
$color-gray-06: #f0efee;

$color-brand-main: $color-brand-red-brown;
$color-brand-dark: $color-brand-red-brown;
$color-brand-light: $color-brand-apple-blossom-red-lighter;
$color-brand-lightest: $color-brand-shilo-red-lightest;
$color-brand-accent: $color-brand-apple-blossom-red-lighter;
$color-brand-background: $color-brand-la-croix-peach;
$color-brand-link: $color-brand-red-brown;

$color-facebook: #405c92;
$color-facebook--muted: #405c92;
$color-pinterest: #c9232d;
$color-pinterest--muted: #c9232d;
$color-email: $color-gray-53;
$color-email--muted: $color-gray-53;

$color-text-gray: $color-gray-74;

$color-newsletter-error: $color-brand-bronze;

$color-btn-background: $color-brand-main;
$color-btn-background-hover: $color-brand-blush;
$color-btn-text: $color-white;

@mixin button(
  $hash-shadow-color-1: $color-brand-bronze,
  $hash-shadow-color-2: transparent
) {
  position: relative;
  background-color: $color-brand-red-brown;
  border-radius: rem(4px);
  padding: rem(12px) rem(30px);
  font-size: 1rem;
  line-height: 1;
  font-family: $sans-serif;
  font-weight: 800;
  color: $color-white;
  transition: background-color 0.15s ease;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $color-brand-blush;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: rem(4px);
    transform: translate(#{rem(6px)}, #{rem(6px)});
    background-size: rem(4px) rem(4px);
    background-position: bottom right;
    background-image: linear-gradient(
      -135deg,
      rgba($hash-shadow-color-1, 0.7) 15.11%,
      $hash-shadow-color-2 15.11%,
      $hash-shadow-color-2 50%,
      rgba($hash-shadow-color-1, 0.7) 50%,
      rgba($hash-shadow-color-1, 0.7) 64.11%,
      $hash-shadow-color-2 64.11%,
      $hash-shadow-color-2 100%
    );
  }
}

body {
  font-family: $serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

%link {
  color: $color-brand-link;
  text-decoration: none;
  background-image: linear-gradient(
    to right,
    $color-brand-link 0,
    $color-brand-link 100%
  );
  background-position: 0 97%;
  background-repeat: repeat-x;
  background-size: 100% rem(1px);
  transition: background-image 0.25s ease, color 0.25s ease;

  &:hover,
  &:focus {
    color: $color-brand-endeavour-blue;
    background-image: linear-gradient(
      to right,
      $color-brand-endeavour-blue 0,
      $color-brand-endeavour-blue 100%
    );
  }
}

a:not([class]) {
  @extend %link;
}

.hero__text-container,
.content {
  ol {
    @include ordered-list($sans-serif);
  }

  ul:not(
      .carousel-slide__pagination,
      .splide__list,
      .quiz-question__answer-list
    ) {
    @include unordered-list('\2B29');
    padding-left: rem(14px);

    & > li {
      padding-left: rem(8px);
    }
  }
}
