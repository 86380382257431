/*
 Naming Convention Details:
  Color variable name is determined by the K value from a color's corresponding CMYK value.
  i.e.  #F7F7F7 'K' value from it's corresponding CMYK value (0%, 0%, 0%, 3%) is '3'; therefore the variable name assigned is '$color-gray-03'
  Any anomalies (different tints of c m y) should be left out of the generic file and placed into Vertical specific file as necessary
 */

$color-white: #fff;

$color-gray-03: #f7f7f7;
$color-gray-04: #f4f4f4;
$color-gray-05: #f1f1f1;
$color-gray-06: #efefef;
$color-gray-07: #eee;
$color-gray-09: #e8e8e8;
$color-gray-10: #e5e5e5;
$color-gray-12: #e0e0e0;
$color-gray-13: #ddd;
$color-gray-15: #d9d9d9;
$color-gray-16: #d6d6d6;
$color-gray-17: #d4d4d4;
$color-gray-19: #cfcfcf;
$color-gray-20: #ccc;
$color-gray-22: #c8c8c8;
$color-gray-24: #c2c2c2;
$color-gray-27: #bbb;
$color-gray-32: #adadad;
$color-gray-33: #aaa;
$color-gray-34: #a8a8a8;
$color-gray-35: #a5a5a5;
$color-gray-40: #989898;
$color-gray-41: #979797;
$color-gray-44: #8f8f8f;
$color-gray-47: #888;
$color-gray-49: #828282;
$color-gray-50: #808080;
$color-gray-53: #777;
$color-gray-54: #757575;
$color-gray-55: #737373;
$color-gray-56: #6f6f6f;
$color-gray-59: #696969;
$color-gray-60: #666;
$color-gray-61: #646464;
$color-gray-62: #616161;
$color-gray-65: #595959;
$color-gray-67: #555;
$color-gray-68: #515151;
$color-gray-71: #4a4a4a;
$color-gray-73: #444;
$color-gray-74: #424242;
$color-gray-80: #333;
$color-gray-84: #282828;
$color-gray-87: #222;
$color-gray-90: #191919;
$color-gray-93: #111;
$color-gray-94: #101010;
$color-gray-95: #0e0e0e;

$color-black: #000;
