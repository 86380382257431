@mixin unordered-list($list-style-type: disc) {
  list-style: $list-style-type;
  padding-left: rem(20px);
}

@mixin ordered-list(
  $font-family: inherit,
  $font-weight: normal,
  $hasLeadingZero: false
) {
  @if $hasLeadingZero {
    list-style: none;
    counter-reset: section;
    padding-left: 1.765em;

    & > li {
      counter-increment: section;

      &::before {
        content: counter(section, decimal-leading-zero);
        position: absolute;
        transform: translateX(-1.765em);
        color: var(--mg-color-list-decorator-count);
        font-family: $font-family;
        font-weight: $font-weight;
      }
    }
  } @else {
    list-style: decimal;
    padding-left: rem(20px);

    & > li {
      padding-left: rem(6px);

      &::marker {
        color: var(--mg-color-list-decorator-count);
        font-family: $font-family;
        font-weight: $font-weight;
      }
    }
  }
}

// Use when list styled with ::marker has center aligned text
%centered-list {
  list-style-position: inside;
  padding-left: 0;

  & > li {
    width: fit-content;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
