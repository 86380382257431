/*------------------------------------*\
    $RESET
\*------------------------------------*/

/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer,
nav,
section,
article,
hgroup,
figure {
  display: block;
}

ul {
  list-style: none;
}

/* Force Firefox to have an initial height */
[contenteditable]::before {
  content: '\feff';
  display: inline-block;
  width: 0;
}

a img {
  border-width: 0;
}
