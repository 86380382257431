@import './../../../../assets/css/partials/reset';
@import './../../../../assets/css/partials/liquor';
@import './../../../../assets/css/partials/_media-queries.scss';

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

@font-face {
  font-family: 'haboro-contrast-normal';
  src: url('https://use.typekit.net/af/9be36f/00000000000000007735bdd3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9be36f/00000000000000007735bdd3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'haboro-contrast-normal';
  src: url('https://use.typekit.net/af/4afb60/00000000000000007735bdd6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4afb60/00000000000000007735bdd6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'haboro-contrast-normal';
  src: url('https://use.typekit.net/af/460e46/00000000000000007735bdd9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/460e46/00000000000000007735bdd9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'haboro-contrast-normal';
  src: url('https://use.typekit.net/af/cd3438/00000000000000007735bdda/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/cd3438/00000000000000007735bdda/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'haboro-contrast-normal';
  src: url('https://use.typekit.net/af/f40a77/00000000000000007735bdde/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f40a77/00000000000000007735bdde/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'haboro-contrast-normal';
  src: url('https://use.typekit.net/af/b56a5a/00000000000000007735bde0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b56a5a/00000000000000007735bde0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  src: url('https://www.liquor.com/static/fonts/montserrat-v14-latin-700.woff2')
      format('woff2'),
    url('https://www.liquor.com/static/fonts/montserrat-v14-latin-700.woff')
      format('woff');
}

$heading-font: 'haboro-contrast-normal', Helvetica, sans-serif;
$body-font: 'EB Garamond', Georgia, serif;
$sans-serif: 'Montserrat', Helvetica, sans-serif;
