@use 'sass:math';

@mixin ie-fallback {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@function em-calc($value, $baseline: 16px) {
  @return math.div($value, $baseline) * 1em;
}

$breakpoints: (
  xxs: em-calc(375px),
  xs: em-calc(560px),
  sm: em-calc(768px),
  md: em-calc(864px),
  lg: em-calc(1024px),
  xl: em-calc(1440px),
  xxl: em-calc(1920px),
);

$media-features: (
  anyHover: 'any-hover: hover',
  noAnyHover: 'any-hover: none',
  hover: 'hover: hover',
  noHover: 'hover: none',
  landscape: 'orientation: landscape',
  portrait: 'orientation: portrait',
  darkMode: 'prefers-color-scheme: dark',
  lightMode: 'prefers-color-scheme: light',
  useMotion: 'prefers-reduced-motion: no-preference',
  reduceMotion: 'prefers-reduced-motion: reduce',
);

@function is-number($value) {
  @return type-of($value) == 'number';
}

@function is-em($value) {
  @return index('em', unit($value)) != null;
}

@function is-valid-value($value) {
  @return map-has-key($breakpoints, $value) or has-unit($value);
}

@function has-unit($value) {
  @return is-number($value) and index('px' 'em', unit($value)) != null;
}

@function get-value($value) {
  @if not is-number($value) and map-has-key($breakpoints, $value) {
    @return map-get($breakpoints, $value);
  }

  @return if(is-em($value), $value, em-calc($value));
}

@function generate-width-feature($size, $type) {
  @if $type == 'max' {
    $size: $size - em-calc(1px);
  }

  @return '(#{$type}-width: #{$size})';
}

@function generate-between-feature($min, $max) {
  @if $min > $max {
    @error 'The min value, `#{$min}`, must be less than the max value, `#{$max}`';
  }

  $min-width: generate-width-feature($min, 'min');
  $max-width: generate-width-feature($max, 'max');

  @return '#{$min-width} and #{$max-width}';
}

@function generate-media-features($width-feature, $values) {
  $list: ('screen and #{$width-feature}');

  @if length($values) {
    @each $value in $values {
      @if map-has-key($media-features, $value) {
        $list: append($list, 'and (#{map-get($media-features, $value)})');
      } @else {
        @warn '#{$value} is not valid and was not added to the media query';
      }
    }
  }

  @return $list;
}

@mixin use-mq($valueOne, $valueTwo: 'min', $values...) {
  @if is-valid-value($valueOne) {
    $width-feature: '';

    @if $valueTwo == 'min' or $valueTwo == 'max' {
      $width-feature: generate-width-feature(get-value($valueOne), $valueTwo);
    } @else if is-valid-value($valueTwo) {
      $min: get-value($valueOne);
      $max: get-value($valueTwo);
      $width-feature: generate-between-feature($min, $max);
    } @else {
      @error 'Invalid argument: #{$valueTwo}. The second argument must be min or max';
    }

    @media #{generate-media-features($width-feature, $values)} {
      @content;
    }
  } @else {
    @error 'Invalid argument: #{$valueOne}. Use #{map-keys($breakpoints)} or a px/em numerical value';
  }
}
