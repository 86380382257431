@import '../reset';

$color-primary: #0053c0;
$color-secondary: #4294ff;
$color-tertiary: #014ecb;
$color-focus: $color-tertiary;

:root {
  // Font Family
  --mg-font-primary: Georgia, Cambria, 'Times New Roman', Times, serif;
  --mg-font-secondary: 'Lucida Grande', Tahoma, Sans-Serif;
  --mg-font-family-lion: var(--mg-font-primary);
  --mg-font-family-zebra: var(--mg-font-primary);
  --mg-font-family-camel: var(--mg-font-primary);
  --mg-font-family-goat: var(--mg-font-primary);
  --mg-font-family-gorilla: var(--mg-font-primary);
  --mg-font-family-monkey: var(--mg-font-primary);
  --mg-font-family-dog: var(--mg-font-secondary);
  --mg-font-family-cat: var(--mg-font-secondary);
  --mg-font-family-rabbit: var(--mg-font-secondary);
  --mg-font-family-squirrel: var(--mg-font-secondary);
  --mg-font-family-mouse: var(--mg-font-secondary);

  // Font Sizes
  --mg-font-size-lion: #{rem(32px)};
  --mg-font-size-zebra: #{rem(28px)};
  --mg-font-size-camel: #{rem(28px)};
  --mg-font-size-goat: #{rem(24px)};
  --mg-font-size-gorilla: #{rem(18px)};
  --mg-font-size-monkey: #{rem(18px)};
  --mg-font-size-dog: #{rem(18px)};
  --mg-font-size-cat: #{rem(16px)};
  --mg-font-size-rabbit: #{rem(14px)};
  --mg-font-size-squirrel: #{rem(14px)};
  --mg-font-size-mouse: #{rem(12px)};

  @include use-mq(md) {
    --mg-font-size-lion: #{rem(48px)};
    --mg-font-size-zebra: #{rem(36px)};
    --mg-font-size-gorilla: #{rem(24px)};
  }

  // Font Weights
  --mg-font-weight-lion: bold;
  --mg-font-weight-zebra: bold;
  --mg-font-weight-camel: bold;
  --mg-font-weight-goat: bold;
  --mg-font-weight-gorilla: bold;
  --mg-font-weight-monkey: bold;
  --mg-font-weight-dog: normal;
  --mg-font-weight-cat: normal;
  --mg-font-weight-rabbit: normal;
  --mg-font-weight-squirrel: bold;
  --mg-font-weight-mouse: normal;

  // Font Variants
  --mg-font-variant-ligatures: normal;
  --mg-font-feature-settings: normal;

  // Colors
  --mg-color-body: #{$color-white};
  --mg-color-primary: #{$color-primary};
  --mg-color-secondary: #{$color-secondary};
  --mg-color-tertiary: #e7f2ff;

  --mg-color-primary-rgb: #{hexToRGB($color-primary)};
  --mg-color-secondary-rgb: #{hexToRGB($color-secondary)};
  --mg-color-tertiary-rgb: #{hexToRGB($color-tertiary)};
  --mg-color-text-heading-rgb: #{hexToRGB($color-gray-95)};

  --mg-color-info-emphasis: #014ecb;
  --mg-color-info-muted: #e6eefa;

  --mg-color-text: #{$color-gray-95};
  --mg-color-text-muted: #{$color-gray-65};
  --mg-color-text-heading: var(--mg-color-text);
  --mg-color-list-decorator-bullet: var(--mg-color-primary);
  --mg-color-list-decorator-count: var(--mg-color-text);

  --mg-color-link-focus: var(--mg-color-info-emphasis);
  --mg-color-icon-fill: var(--mg-color-primary);
  --mg-color-icon-stroke: var(--mg-color-primary);

  --mg-color-eyebrow: var(--mg-color-primary);

  // Background Colors
  --mg-background-color-body: #{$color-white};

  // Button
  --mg-button-bg-primary: var(--mg-color-primary);
  --mg-button-bg-hover-primary: #{$color-gray-95};
  --mg-button-font: var(--mg-font-secondary);
  --mg-button-text-primary: #{$color-white};
  --mg-button-text-primary-hover: #{$color-white};
  --mg-button-focus-outline-color: #{$color-focus};
  --mg-button-border-style: none;
  --mg-button-border-color: transparent;
  --mg-button-border-width: 0;
  --mg-button-border-color-hover: var(--mg-button-border-color);
  --mg-button-border-radius: 0;

  // Aspect Ratios
  --mg-ratio-square: 1;
  --mg-ratio-landscape: 4/3;
  --mg-ratio-portrait: 3/4;
  --mg-ratio-widescreen: 16/9;
  --mg-ratio-ultrawide: 18/5;
  --mg-ratio-obscure: 82/35;
  --mg-ratio-ultra-panavision: 124/45;

  // Letterspacing
  --mg-font-letterspacing-0: -0.05em;
  --mg-font-letterspacing-1: 0.025em;
  --mg-font-letterspacing-2: 0.05em;
  --mg-font-letterspacing-3: 0.075em;
  --mg-font-letterspacing-4: 0.15em;
  --mg-font-letterspacing-5: 0.5em;
  --mg-font-letterspacing-6: 0.75em;
  --mg-font-letterspacing-7: 1em;

  --mg-link-underline-offset-lion: #{rem(9px)};
  --mg-link-underline-offset-zebra: var(--mg-size-2);
  --mg-link-underline-offset-goat: #{rem(7px)};
  --mg-link-underline-offset-gorilla: #{rem(5px)};
  --mg-link-underline-offset-cat: #{rem(3px)};
  --mg-link-underline-offset-rabbit: #{rem(3px)};
  --mg-link-underline-offset-mouse: #{rem(2px)};
  --mg-link-underline-offset: var(--mg-size-1);

  @include use-mq(md) {
    --mg-link-underline-offset-lion: var(--mg-size-3);
    --mg-link-underline-offset-zebra: #{rem(11px)};
    --mg-link-underline-offset-gorilla: var(--mg-size-2);
  }

  //  Line Height
  --mg-font-lineheight-00: 0.95;
  --mg-font-lineheight-0: 1.1;
  --mg-font-lineheight-1: 1.25;
  --mg-font-lineheight-2: 1.375;
  --mg-font-lineheight-3: 1.5;
  --mg-font-lineheight-4: 1.75;
  --mg-font-lineheight-5: 2;

  // Sizing
  --mg-size-000: #{rem(-8px)};
  --mg-size-00: #{rem(-4px)};
  --mg-size-1: #{rem(4px)};
  --mg-size-2: #{rem(8px)};
  --mg-size-3: #{rem(12px)};
  --mg-size-4: #{rem(16px)};
  --mg-size-5: #{rem(20px)};
  --mg-size-6: #{rem(24px)};
  --mg-size-7: #{rem(28px)};
  --mg-size-8: #{rem(32px)};
  --mg-size-9: #{rem(48px)};
  --mg-size-10: #{rem(64px)};
  --mg-size-11: #{rem(80px)};
  --mg-size-12: #{rem(96px)};
  --mg-size-13: #{rem(120px)};
  --mg-size-14: #{rem(160px)};
  --mg-size-15: #{rem(240px)};
  --mg-size-16: #{rem(320px)};
  --mg-size-17: #{rem(480px)};
  --mg-content-gutter: var(--mg-size-5);
  --mg-content-gutters: calc(var(--mg-content-gutter) * 2);
  --mg-component-width-max-md: #{rem(700px)};
  --mg-component-width-max-lg: #{rem(820px)};
  --mg-component-width-max-xl: #{rem(1000px)};
  --mg-component-width-max-xxl: #{rem(1920px)};

  // Icons
  --mg-icon-video-timer: url(#{staticPathPrefixer('/svg/graphite-icon-video-timer.svg')});
  --mg-icon-video-play: url(#{staticPathPrefixer('/svg/graphite-icon-video-play.svg')});
}
