$serif: 'Lusitana', Georgia, serif;
$sans-serif: 'Montserrat', Helvetica, sans-serif;

$color-brand-red-brown: #9d2a2c;
$color-brand-blush: #a45241;
$color-brand-bronze: #ce7c2e;
$color-brand-sapphire-dark: #06273a;
$color-brand-sapphire-darker: #031621;
$color-brand-shilo-red-lightest: #e3aa9d;

$color-text-default: $color-black;
